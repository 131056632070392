import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <Header />
      <main className="flex-grow container mx-auto px-4 py-8 max-w-4xl">
        <h1 className="text-4xl font-bold mb-8 text-center text-black">
          Privacy Policy
        </h1>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
          <p className="mb-4">
            PodFast ("we," "our," or "us") is committed to protecting your
            privacy. This Privacy Policy explains how we collect, use, disclose,
            and safeguard your information when you use our platform.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            Information We Collect
          </h2>

          <h3 className="text-xl font-semibold mb-3">Personal Information</h3>
          <ul className="list-disc ml-6 mb-4">
            <li>Username</li>
            <li>Email address</li>
            <li>Name</li>
            <li>Password</li>
            <li>Account preferences</li>
            <li>User queries and interactions</li>
          </ul>

          <h3 className="text-xl font-semibold mb-3">Technical Information</h3>
          <ul className="list-disc ml-6 mb-4">
            <li>Device information</li>
            <li>Browser type and version</li>
            <li>IP address</li>
            <li>Access timestamps</li>
            <li>Usage patterns</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            Data Storage and Retention
          </h2>

          <h3 className="text-xl font-semibold mb-3">Storage</h3>
          <ul className="list-disc ml-6 mb-4">
            <li>All data is securely stored on cloud servers</li>
            <li>Industry-standard encryption protocols</li>
            <li>Regular security audits and updates</li>
          </ul>

          <h3 className="text-xl font-semibold mb-3">Retention Periods</h3>
          <ul className="list-disc ml-6 mb-4">
            <li>Account information: Retained while account is active</li>
            <li>Generated podcasts: 3 years from creation date</li>
            <li>User queries: Retained for service improvement</li>
            <li>
              Inactive accounts: Data purged after 12 months of inactivity
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            How We Use Your Information
          </h2>

          <h3 className="text-xl font-semibold mb-3">Core Services</h3>
          <ul className="list-disc ml-6 mb-4">
            <li>Account management</li>
            <li>Podcast generation</li>
            <li>Platform functionality</li>
            <li>Service optimization</li>
          </ul>

          <h3 className="text-xl font-semibold mb-3">Communications</h3>
          <ul className="list-disc ml-6 mb-4">
            <li>Platform updates</li>
            <li>Service notifications</li>
            <li>Product announcements</li>
            <li>Promotional content (with opt-out option)</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Data Protection</h2>

          <h3 className="text-xl font-semibold mb-3">Security Measures</h3>
          <ul className="list-disc ml-6 mb-4">
            <li>End-to-end encryption</li>
            <li>Secure access controls</li>
            <li>Regular security audits</li>
            <li>Firewall protection</li>
            <li>Multi-factor authentication</li>
            <li>Automated threat detection</li>
          </ul>

          <h3 className="text-xl font-semibold mb-3">
            International Compliance
          </h3>
          <ul className="list-disc ml-6 mb-4">
            <li>GDPR compliance</li>
            <li>CCPA compliance</li>
            <li>International data protection standards</li>
            <li>Regular compliance audits</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            Analytics and Tracking
          </h2>

          <h3 className="text-xl font-semibold mb-3">Usage Analytics</h3>
          <ul className="list-disc ml-6 mb-4">
            <li>Platform performance monitoring</li>
            <li>User experience optimization</li>
            <li>Service improvement metrics</li>
            <li>Aggregated usage statistics</li>
          </ul>

          <h3 className="text-xl font-semibold mb-3">Cookies and Tracking</h3>
          <ul className="list-disc ml-6 mb-4">
            <li>Essential cookies for platform functionality</li>
            <li>Analytics cookies (optional)</li>
            <li>Session tracking for security</li>
            <li>User preference storage</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Your Privacy Rights</h2>

          <h3 className="text-xl font-semibold mb-3">Data Control</h3>
          <ul className="list-disc ml-6 mb-4">
            <li>Right to access personal data</li>
            <li>Right to request data deletion</li>
            <li>Right to opt-out of communications</li>
            <li>Right to update personal information</li>
          </ul>

          <h3 className="text-xl font-semibold mb-3">Data Requests</h3>
          <ul className="list-disc ml-6 mb-4">
            <li>Processing time: 5 business days</li>
            <li>Contact: support@podfast.io</li>
            <li>No data export service available</li>
            <li>Verification required for requests</li>
          </ul>
        </section>

         <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Data usage & collection</h2>

          <h3 className="text-xl font-semibold mb-3">How We Collect Information</h3>
          <p>We collect personal information when users sign up for our platform. This includes the following methods:</p>
          <ul className="list-disc ml-6 mb-4">
            <li>Direct user input during account creation (e.g., username, email, name, and password).</li>
            <li>Data submitted via forms or queries.</li>
            <li>Automatic data collection through platform interactions.</li>
          </ul>

          <h3 className="text-xl font-semibold mb-3">Why We Collect Information and How We Use It</h3>
          <p>The information we collect is essential for:</p>
          <ul className="list-disc ml-6 mb-4">
            <li>Authentication: Verifying user identity during login and secure access to the platform.</li>
            <li>Core Services: Ensuring the proper functioning of the platform and delivering personalized experiences.</li>
            <li>Security Measures: Protecting accounts and maintaining system integrity.</li>
            <li>Service Optimization: Improving user experience and enhancing platform functionality.</li>
            <li>Data Usage: We may use your personal information to send you updates about our products, app features, and services, including marketing and promotional content that we believe may interest you</li>
          </ul>
        </section>
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Data Processing</h2>

          <h3 className="text-xl font-semibold mb-3">AI System</h3>
          <ul className="list-disc ml-6 mb-4">
            <li>No personal data used for AI training</li>
            <li>Query processing for content generation</li>
            <li>Automated content moderation</li>
            <li>No third-party data sharing</li>
          </ul>

          <h3 className="text-xl font-semibold mb-3">Third-Party Services</h3>
          <ul className="list-disc ml-6 mb-4">
            <li>Limited to essential service providers</li>
            <li>Strict confidentiality agreements</li>
            <li>Regular vendor security assessments</li>
            <li>No third-party marketing integrations</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Children's Privacy</h2>
          <ul className="list-disc ml-6 mb-4">
            <li>Minimum age requirement: 13 years</li>
            <li>Parental consent required for users under 18</li>
            <li>Immediate deletion of unauthorized minor data</li>
            <li>Regular age verification checks</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            Communication Preferences
          </h2>

          <h3 className="text-xl font-semibold mb-3">Opt-Out Options</h3>
          <ul className="list-disc ml-6 mb-4">
            <li>Email: support@podfast.io</li>
            <li>Promotional content opt-out</li>
            <li>Platform updates opt-out</li>
            <li>Response within 48 hours</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            Security Incident Response
          </h2>

          <h3 className="text-xl font-semibold mb-3">Breach Protocol</h3>
          <ul className="list-disc ml-6 mb-4">
            <li>Immediate incident assessment</li>
            <li>User notification within 72 hours</li>
            <li>Regulatory compliance reporting</li>
            <li>Remediation measures implementation</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            Updates to Privacy Policy
          </h2>
          <ul className="list-disc ml-6 mb-4">
            <li>Regular policy reviews</li>
            <li>User notification of significant changes</li>
            <li>30-day notice for material changes</li>
            <li>Continued use implies acceptance</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Contact Information</h2>
          <p className="mb-2">For privacy-related inquiries:</p>
          <ul className="list-disc ml-6 mb-4">
            <li>Email and Support: support@podfast.io</li>
            <li>Response time: Within 48 hours</li>
            <li>Emergency contact available for security concerns</li>
          </ul>
        </section>

        <p className="mt-8 text-sm text-gray-600">
          This Privacy Policy is designed to be transparent and user-friendly
          while maintaining comprehensive coverage of our data handling
          practices. By using PodFast, you agree to the terms outlined in this
          policy.
        </p>
      </main>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
