import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import PodFastForm from "./pages/PodFastForm";
import AuthForm from "./pages/AuthForm";
import About from "./pages/About";
import GeneratePodcast from "./components/GeneratePodcast";
import SharedPodcastView from "./components/SharedPodcastView";
import Library from "./components/Library";
import { AuthProvider } from "./context/AuthContext";
import { LibraryProvider } from "./context/LibraryContext";
import HomePage from "./pages/HomeNew";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ResetPassword from "./pages/ResetPassword";
//import AutoLogout from "./components/AutoLogout";

function App() {
  const [libraryUpdateTrigger, setLibraryUpdateTrigger] = useState(0);

  const handlePodcastGenerated = () => {
    setLibraryUpdateTrigger((prev) => prev + 1);
  };

  return (
    <AuthProvider>
      <LibraryProvider>
        <Router>
          {/*<AutoLogout />*/}
          <Routes>
            <Route path="/" element={<HomePage page="home" />} />
            <Route path="/shared/:shareId" element={<SharedPodcastView />} />
            <Route path="/auth" element={<AuthForm />} />
            <Route path="/get-started" element={<PodFastForm />} />
            <Route path="/about" element={<About />} />
            <Route path="/terms" element={<TermsOfUse />} />
            {/*<Route path="/privacy" element={<PrivacyPolicy />} />*/}
            <Route
              path="/generate-podcast"
              element={
                <GeneratePodcast onPodcastGenerated={handlePodcastGenerated} />
              }
            />
            <Route
              path="/library"
              element={<Library updateTrigger={libraryUpdateTrigger} />}
            />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Routes>
        </Router>
      </LibraryProvider>
    </AuthProvider>
  );
}

export default App;