import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const Header = ({ page }) => {
  const { isLoggedIn, logout } = useAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = () => {
    console.log("page", page);
    logout();
    if (page === "get-started") {
      navigate("/auth");
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navLinkClass = (linkPage) =>
    `${
      page === "text-black"
    } hover:font-bold transition-colors duration-200 block py-2 px-4 text-lg md:text-lg md:p-0 ${
      page === linkPage ? "font-bold" : ""
    }`;

  return (
    <header>
      <div className="container mx-auto pt-6 pb-4">
        <div className="flex justify-between items-center bg-white rounded-[62px] shadow-sm p-6">
          <Link
            to="/"
            className="flex items-center hover:opacity-80 transition-opacity duration-200"
          >
            <img
              src={require("../assets/images/new_logo1.png")}
              alt="PodFast.io"
              className="w-32"
            />
            {/*<span className="ml-1 text-xl font-semibold text-black">
              Podfast.io
            </span>*/}
          </Link>
          {/*<div className="hidden md:block text-center">
            <span className="underline text-lg font-medium">
              Early Access Beta - Experience It First 🚀
            </span>
          </div>*/}
          <div className="md:hidden">
            <button
              onClick={toggleMenu}
              className="text-gray-600 hover:text-gray-900"
            >
              <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                {isMenuOpen ? (
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                  />
                ) : (
                  <path
                    fillRule="evenodd"
                    d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                  />
                )}
              </svg>
            </button>
          </div>
          <nav
            className={`md:flex text-black ${isMenuOpen ? "block" : "hidden"}`}
          >
            <ul className="md:flex md:space-x-6 flex-col md:flex-row mt-4 md:mt-0">
              <li>
                <Link to="/" className={navLinkClass("home")}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" className={navLinkClass("about")}>
                  About
                </Link>
              </li>
              <li>
                <Link to="/get-started" className={navLinkClass("get-started")}>
                  Get Started
                </Link>
              </li>
              {isLoggedIn && (
                <li>
                  <button onClick={handleLogout} className={navLinkClass("")}>
                    Logout
                  </button>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
